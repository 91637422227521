import { EventEmitter, Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { catchError, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CONSTANTS } from '../CONSTANTS';

const credAppPresentCheckUrl = 'https://cred-web-stg.dreamplug.in/checkout/pay';

@Injectable({
    providedIn: 'root',
})
export class MarcheAPIService {
    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
        this.recentSearchClear();
    }

    private initObservable: Observable<string> = this.createInit();
    redirectEvent: EventEmitter<any> = new EventEmitter<any>();
    sessionId: string = btoa(Math.random().toString(36));

    private createInit(): Observable<string> {
        const guestID: string | null = localStorage.getItem(
            CONSTANTS.GUEST_ID_HEADER_NAME
        );

        if (guestID) {
            return of(guestID);
        } else {
            return this.httpClient
                .head(`${environment.host}/fn/init`, {
                    observe: 'response',
                })
                .pipe(
                    shareReplay(),
                    switchMap(res => {
                        let gID: string;

                        const guID = localStorage.getItem(
                            CONSTANTS.GUEST_ID_HEADER_NAME
                        );

                        if (!!guID) {
                            gID = guID;
                        } else {
                            const guestIDFromHeader = res.headers.get(
                                CONSTANTS.GUEST_ID_HEADER_NAME
                            );
                            if (!!guestIDFromHeader) {
                                gID = guestIDFromHeader;
                                localStorage.setItem(
                                    CONSTANTS.GUEST_ID_HEADER_NAME,
                                    gID
                                );
                            } else {
                                throw Error(
                                    '!! INIT CALL HAS MISSING GUEST ID CHECK IMMEDIATELY !!'
                                );
                            }
                        }

                        return of(gID);
                    })
                );
        }
    }

    getRequest<T>(
        endpoint: string,
        parameters?: { [p: string]: string | string[] }
    ): Observable<T | null> {
        return this.getHostRequest(endpoint, false, parameters);
    }

    getCDNRequest<T>(
        endpoint: string,
        parameters?: { [p: string]: string | string[] }
    ): Observable<T | null> {
        return this.getHostRequest(endpoint, true, parameters);
    }

    private getHostRequest<T>(
        endpoint: string,
        cdnHost: boolean,
        parameters?: { [p: string]: string | string[] }
    ): Observable<T | null> {
        let params: { [p: string]: string | string[] } = {};
        if (!!parameters) {
            params = this.senitizeFilter(parameters);
        }
        const getHost = cdnHost ? environment.cdnHost : environment.host;
        return this.getHTTPHeaders().pipe(
            switchMap(headers => {
                return this.httpClient
                    .get<T>(`${getHost}${endpoint}`, {
                        headers,
                        params,
                    })
                    .pipe(
                        tap((res: any) => {
                            if (res?.meta?.redirects) {
                                this.metaRedirects(res?.meta);
                            }
                        })
                    );
            }),
            catchError(err => {
                if (err.status === 401) {
                    this.router.navigate(['/ap']);
                } else if (err.status === 301) {
                    if (err?.error?.meta?.redirects) {
                        this.metaRedirects(err?.error?.meta);
                        return of(err?.error);
                    }
                } else {
                    this.logError(err);
                }
                return of(null);
            })
        );
    }

    postRequest<T>(
        endpoint: string,
        parameters?: { [p: string]: string | string[] },
        body?: any,
        customHeader = false
    ): Observable<T | null> {
        const params = parameters;
        return this.getHTTPHeaders(customHeader).pipe(
            switchMap(headers =>
                this.httpClient
                    .post<T>(
                        endpoint.includes('https')
                            ? endpoint
                            : `${environment.host}${endpoint}`,
                        body,
                        {
                            headers,
                            params,
                        }
                    )
                    .pipe(
                        tap((res: any) => {
                            if (res?.meta?.redirects) {
                                this.metaRedirects(res?.meta);
                            }
                        })
                    )
            ),
            catchError(err => {
                if (err.status === 401) {
                    this.router.navigate(['/ap']);
                } else {
                    this.logError(err);
                }
                return of(null);
            })
        );
    }

    /*private getHTTPHeaders(): Observable<{}> {
        return this.authenticationService.getAuthToken().pipe(
            switchMap(token => {
                return this.initObservable.pipe(
                    switchMap(gid => {
                        const header: {
                            authorization?: string;
                            // 'x-store-id': string;
                            // 'x-api-key' : string;
                            'x-guest-id': string | undefined;
                            'x-client-type': string;
                            'x-device-type': string;
                            'x-client-version': string;
                            withCredentials: string;
                            'x-session-id': string;
                        } = {
                            // 'x-store-id': environment.WizzySearch.StoreID,
                            // 'x-api-key': environment.WizzySearch.APIKey,
                            'x-guest-id': undefined,
                            'x-client-type': 'web',
                            'x-device-type': this.getDeviceType(),
                            'x-client-version':
                                environment.appVersion.toString(),
                            'x-session-id': this.sessionId,
                            withCredentials: 'true',
                        };
                        if (token) {
                            header.authorization = `Bearer ${token}`;
                        }
                        if (gid) {
                            header['x-guest-id'] = gid;
                        }
                        return of(header);
                    })
                );
            })
        );
    }*/
    private getHTTPHeaders(customHeader?: boolean): Observable<{}> {
        if (customHeader) {
            return this.authenticationService.getAuthToken().pipe(
                switchMap(token => {
                    return this.initObservable.pipe(
                        switchMap(gid => {
                            const header: {
                                'x-store-id'?: string;
                                'x-api-key': string | undefined;
                                'x-wizzy-userId': string | null;
                            } = {
                                'x-store-id': environment.WizzySearch.StoreID,
                                'x-api-key': environment.WizzySearch.APIKey,
                                'x-wizzy-userId':
                                    this.authenticationService.getUserId(),
                            };

                            return of(header);
                        })
                    );
                })
            );
        }
        return this.authenticationService.getAuthToken().pipe(
            switchMap(token => {
                return this.initObservable.pipe(
                    switchMap(gid => {
                        const header: {
                            authorization?: string;
                            'x-guest-id': string | undefined;
                            'x-client-type': string;
                            'x-device-type': string;
                            'x-client-version': string;
                            withCredentials: string;
                            'x-session-id': string;
                        } = {
                            'x-guest-id': undefined,
                            'x-client-type': 'web',
                            'x-device-type': this.getDeviceType(),
                            'x-client-version':
                                environment.appVersion.toString(),
                            'x-session-id': this.sessionId,
                            withCredentials: 'true',
                        };
                        if (token) {
                            header.authorization = `Bearer ${token}`;
                        }
                        if (gid) {
                            header['x-guest-id'] = gid;
                        }
                        return of(header);
                    })
                );
            })
        );
    }

    logError(httpErrorResponse: HttpErrorResponse): void {
        const error = httpErrorResponse.error;
        let message = httpErrorResponse.statusText;
        if (!!error?.message) {
            message = error.message;
        }
        if (!!error?.errors) {
            message = error.errors[0].msg.split(':')[2].toUpperCase();
        }
        if (message === 'Unknown Error' && error instanceof ProgressEvent) {
            message = 'Please check your internet connection!';
        }
        console.error(message);
    }

    getDeviceType(): string {
        if (window.screen.width <= 480) {
            return 'mobile';
        } else if (window.screen.width <= 780) {
            return 'tablet';
        } else {
            return 'desktop';
        }
    }

    credAppPresentCheck(body: any): Observable<any | null> {
        return this.httpClient.post<any>(credAppPresentCheckUrl, body, {}).pipe(
            tap((res: any) => {
                if (res) {
                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    metaRedirects(meta: any) {
        meta?.redirects.forEach((link: string) => {
            this.redirectEvent.emit(link);
        });
    }
    recentSearchClear() {
        localStorage.removeItem('SearchRecentView');
    }

    senitizeFilter(parameters: { [p: string]: string | string[] }) {
        const key = 'tr';
        delete parameters[key];

        return parameters;
    }

    addToCart(sku: string, token: string, guestId: string): Observable<any> {
        const url = `${environment.host}/fn/bag/add?f=pid~${sku}&action=add-to-cart`;
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'x-guest-id': guestId,
            'Content-Type': 'application/json',
        });
        return this.httpClient.post(url, {}, { headers });
    }
}
